import * as React from "react"
import GatsbyLink from "gatsby-link"

import { UiButton, UiButtonProps } from "./ui/Button"

export type ButtonProps<
  To extends string | undefined = undefined,
  Href extends string | undefined = undefined
> = {
  to?: To
  href?: Href
} & Omit<
  To extends undefined
    ? Href extends undefined
      ? UiButtonProps<"button">
      : UiButtonProps<"a">
    : UiButtonProps<typeof GatsbyLink<{}>>,
  "as"
>

export const Button = <
  To extends string | undefined = undefined,
  Href extends string | undefined = undefined
>(
  props: ButtonProps<To, Href>
) => {
  if (props.to && /^\/(?!\/)/.test(props.to)) {
    return <UiButton {...props} as={GatsbyLink} />
  }

  if (!props.to && !props.href) {
    return <UiButton {...props} as="button" />
  }

  if (props.href) {
    return <UiButton {...props} as="a" href={props.href} />
  }

  return <UiButton {...props} as="a" href={props.to} />
}
