import * as React from "react"
import { Box, BoxOwnProps } from "theme-ui"

export interface UiLinkOwnProps extends BoxOwnProps {
  variant?: "ghost" | "underline" | "underline-on-hover"
}

export type UiLinkProps<As extends React.ElementType> = UiLinkOwnProps &
  Omit<React.ComponentPropsWithRef<As>, "as" | keyof UiLinkOwnProps> & {
    as?: As
  }

export const UiLink = <As extends React.ElementType>({
  variant = "ghost",
  sx,
  ...rest
}: UiLinkProps<As>) => (
  <Box
    {...rest}
    sx={{
      display: "inline-block",
      appearance: "none",
      color: "currentcolor",
      textAlign: "left",
      lineHeight:
        (variant === "underline" || variant === "underline-on-hover") && 1.1,
      textDecoration: "none",
      backgroundColor: "transparent",
      border: 0,
      borderBlockEnd:
        (variant === "underline" || variant === "underline-on-hover") &&
        "1px solid",
      borderBlockEndColor:
        (variant === "underline" && "currentcolor") ||
        (variant === "underline-on-hover" && "transparent"),
      cursor: "pointer",
      transition: "border-block-end-color .2s",
      padding: 0,
      ":hover": {
        borderBlockEndColor: "currentcolor",
      },
      ...sx,
    }}
  />
)
