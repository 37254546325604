const noDivisionCurrencies = ["krw", "jpy"]

export function normalizeAmount(currency, amount) {
  if (!currency) {
    return ""
  }

  let divisor = 100
  if (noDivisionCurrencies.includes(currency.toLowerCase())) {
    divisor = 1
  }

  return Math.floor(amount) / divisor
}

export function formatCartItemPrice(cart, cartItem) {
  const variantPrice = getVariantPrice(cart, cartItem.variant)
  const currencyCode = cart?.region?.currency_code

  return formatMoneyAmount({
    currencyCode,
    amount: variantPrice * cartItem.quantity,
  })
}

export function getTaxRate(cart) {
  if (!cart) {
    return 0
  }

  if ("taxRate" in cart && cart.taxRate !== null) {
    return cart.taxRate / 100
  } else if ("tax_rate" in cart && cart.tax_rate !== null) {
    return cart.tax_rate / 100
  } else if (cart?.region) {
    return cart?.region && cart?.region.tax_rate / 100
  }
  return 0.25
}

export function getSameCurrencyCorrectAmount(prices, cart, currencyCode) {
  if (!prices?.length) {
    return false
  }

  const sameCurrency = prices.filter(
    (p) => p?.currency_code?.toLowerCase() === currencyCode.toLowerCase()
  )

  if (!sameCurrency?.length) {
    return false
  }

  return sameCurrency.reduce((acc, next) => {
    return next.amount > acc.amount ? next : acc
  }, sameCurrency[0])
}

export function getVariantPrice(cart, variant, taxInclusive = false) {
  if (!cart?.id) {
    return undefined
  }

  const currencyCode = cart?.region?.currency_code

  let variantTaxRate = getVariantTaxRate(cart, variant)

  let taxRate = variantTaxRate ? variantTaxRate : getTaxRate(cart)

  if (!variant?.prices?.length) {
    return undefined
  }

  let moneyAmount = getSameCurrencyCorrectAmount(
    variant?.prices,
    cart,
    currencyCode
  )

  if (taxInclusive) {
    return moneyAmount.amount
  }

  if (moneyAmount && moneyAmount.amount) {
    return moneyAmount.amount * (1 + taxRate)
  }

  return undefined
}

export function formatMoneyAmount(moneyAmount, digits, taxRate = 0) {
  let locale = "en-US"
  let currencyCode = moneyAmount.currencyCode || moneyAmount.currency_code

  if (!currencyCode) {
    return ""
  }

  // We need this to display 'Kr' instead of 'DKK'
  if (currencyCode.toLowerCase() === "dkk") {
    locale = "da-DK"
  }

  if (noDivisionCurrencies.includes(currencyCode.toLowerCase())) {
    digits = 0
  }

  const normalizedAmount = normalizeAmount(currencyCode, moneyAmount.amount)

  const numberFormat = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format(normalizedAmount * (1 + taxRate / 100))

  if (currencyCode.toLowerCase() === "cad") {
    return numberFormat.replace("CA$", "$")
  }

  if (currencyCode.toLowerCase() === "aud") {
    return numberFormat.replace("A$", "$")
  }

  return numberFormat
}

export function getVariantTaxRate(cart, variant) {
  if (!variant) {
    return getTaxRate(cart)
  }

  const cartVariant = cart?.items?.find(
    (i) => i.variant_id === variant.variant_id
  )

  if (cartVariant?.tax_lines && cartVariant?.tax_lines?.[0]?.rate) {
    return cartVariant.tax_lines[0].rate / 100
  }

  return getTaxRate(cart)
}

export function getVariantsTotal(cart) {
  let variantsTotal = 0

  if (cart?.items) {
    for (const item of cart?.items) {
      variantsTotal += item.original_total
    }
  }

  return variantsTotal
}

export function formatPrices(cart, prices, digits = 2) {
  let amount = prices
  const currencyCode = cart?.region?.currency_code

  if (typeof prices === "object") {
    amount = prices.find(
      (p) => p.currencyCode.toLowerCase() === currencyCode.toLowerCase()
    )
  }

  if (noDivisionCurrencies.includes(currencyCode.toLowerCase())) {
    digits = 0
  }

  return formatMoneyAmount(
    {
      currencyCode,
      amount: amount,
    },
    digits
  )
}

export function formatVariantPrice(cart, variant, digits = 2, prices) {
  if (!cart || !variant || !prices || !prices[variant.objectId]) return
  const currencyCode = cart.currency || cart?.region?.currency_code
  return formatMoneyAmount(
    {
      currencyCode,
      amount: prices[variant.objectId],
    },
    digits
  )
}

export function formatCartShippingTotal(cart, shippingMethod) {
  const currencyCode = cart?.region?.currency_code
  const shippingTotal =
    cart?.region?.name === "United States"
      ? shippingMethod.subtotal
      : shippingMethod.total

  return formatMoneyAmount({
    currencyCode,
    amount: shippingTotal,
  })
}

export function formatCartSubtotal(cart) {
  let subtotal = cart.subtotal

  const currencyCode = cart?.region?.currency_code

  if (cart.item_tax_total && !cart.discounts?.length) {
    subtotal += cart.item_tax_total
  } else {
    subtotal = subtotal * (1 + getTaxRate(cart))
  }

  if (cart?.region?.name === "United States") {
    subtotal = cart?.subtotal
  }

  return formatMoneyAmount({
    currencyCode,
    amount: subtotal,
  })
}

export function formatVatDeduction({ currencyCode, order }) {
  return formatMoneyAmount({
    currencyCode,
    amount: order.tax_total,
  })
}

export function formatCartTotal(cart, allowNegative = true) {
  const currencyCode = cart?.region?.currency_code
  let amount = cart.total
  if (!allowNegative && cart.total < 0) {
    amount *= -1
  }
  return formatMoneyAmount({
    currencyCode,
    amount,
  })
}

export function formatCartGiftCard(cart) {
  const giftCardTotal =
    "gift_card_total" in cart ? cart.gift_card_total : cart.giftCardTotal
  const currencyCode = cart?.region?.currency_code
  const giftCardTaxTotal =
    "gift_card_tax_total" in cart
      ? cart.gift_card_tax_total
      : cart.giftCardTaxTotal

  return formatMoneyAmount({
    currencyCode,
    amount: giftCardTotal + giftCardTaxTotal,
  })
}

export function formatCartDiscount(cart) {
  const currencyCode = cart?.region?.currency_code

  let discountTotal = 0

  if (cart?.items) {
    for (const item of cart?.items) {
      discountTotal +=
        cart?.region?.name === "United States"
          ? item.discount_total
          : item.original_total - item.total
    }
  }

  return formatMoneyAmount({
    currencyCode,
    amount: discountTotal,
  })
}

export function formatShipping(amount, cart) {
  const currencyCode = cart?.region?.currency_code

  const hasShippingAvailable = cart.discounts.some(
    (d) => d?.rule?.type === "free_shipping"
  )

  return formatMoneyAmount({
    currencyCode,
    amount: hasShippingAvailable ? 0 : amount,
  })
}

export function formatOrderDiscount(order) {
  const currencyCode = order.currencyCode || order.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: order.discount_total,
  })
}

// Calculates the minimum price of a selcted color's sizes.
// If the selected product represents a bedding collection we show the
// minimum price of the pillow product
export const getMinimumPriceOption = (product, cart) => {
  if (!product || !cart) return
  if (!product || !product.variants?.length) return 0
  const currencyCode = cart?.region?.currency_code

  const init =
    product.variants[0]?.prices?.find(
      (ma) => ma.currency_code.toLowerCase() === currencyCode.toLowerCase()
    )?.amount || 0
  const minPrice = product.variants?.reduce((acc, next) => {
    const sameCurrency = next.prices
      .filter(
        (p) => p.currency_code.toLowerCase() === currencyCode.toLowerCase()
      )
      .map((p) => p.amount)

    if (!sameCurrency?.length) {
      return acc
    }

    const current = Math.max(...sameCurrency)

    if (current < acc) {
      return current
    } else {
      return acc
    }
  }, init)

  return minPrice
}
